import React from 'react';
import Section from "../../components/Section";
import Container from "../../components/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/cjs/Col";
import {useTranslation} from "react-i18next";
import Button from "react-bootstrap/Button";
import carImage from "../../images/flatTires.png";


import("./MainSection.scss");

const MainSection = ({ reference, toAbout, toForm }) => {
  const {t} = useTranslation();

  return (
    <Section id="MainSection" reference={reference} >
      <Container>
        <Row>
          <Col lg="6" md="12">
            <h1>{t('main.title')}</h1>
            <p className="intro-text">Welkom bij het Financieel Adviesburo, dé plek waar je eenvoudig uw autoverzekering kunt laten vergelijken door een specialist en niet door een computerprogramma. Een autoverzekering is meer dan een premie. Als onafhankelijk verzekeringsmakelaar streven wij ernaar je te helpen de beste autoverzekering te vinden die past bij jouw behoeften én aan de laagst mogelijke prijs. </p>
            <div>
              <Button variant="outline-primary" onClick={() => toAbout()}>Over ons</Button>
              {' '}
              <Button onClick={() => toForm()}>Vergelijking aanvragen</Button>
            </div>
          </Col>
          <Col lg="6" md="12" className="main-image-container">
            <img className="main-image" src={carImage} id="CarLogo" alt="Financieel Adviesburo"/>
          </Col>
        </Row>
      </Container>
    </Section>
  );
}

export default MainSection;
