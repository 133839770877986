import React from 'react';
import Section from "../../components/Section";
import Container from "../../components/Container";
import ContactForm from "./ContactForm";
import {useTranslation} from "react-i18next";

import "./Contact.scss";
import Col from "react-bootstrap/cjs/Col";
import Row from "react-bootstrap/Row";

const ContactSection = ({reference}) => {
  const {t} = useTranslation();
  return (
    <Section id="ContactSection" reference={reference}>
      <Container>
          <Row>
              <Col lg="12">
                  <h2>{t('contact.title')}</h2>
                  <p>{t('contact.description')}</p>
                  <p>
                      {t('contact.addressInfo')}<br/>
                      {t('contact.contactInfo')}
                  </p>
              </Col>
          </Row>
          <ContactForm/>
      </Container>
        <Container id="Footer">
        {t('contact.footer')}
      </Container>
    </Section>
  )
} ;

export default ContactSection;
