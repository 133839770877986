import React from 'react';
import Section from "../../components/Section";
import Container from "../../components/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/cjs/Col";


import "./FormSection.scss";
import {useTranslation} from "react-i18next";
import InsuranceForm from "./InsuranceForm";

const AboutSection = ({ reference }) => {
  const {t} = useTranslation();
  return (
    <Section id="FormSection" reference={reference}>
      <Container>
        <Row>
          <Col lg="12" className="section-title">
            <h2>{t('form.title')}</h2>
          </Col>
        </Row>
        <Row>
          <Col lg="12">
            <InsuranceForm />
          </Col>
        </Row>
      </Container>
    </Section>
  );
}

export default AboutSection;
