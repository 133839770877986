import React from 'react';
import Section from "../../components/Section";
import Container from "../../components/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/cjs/Col";

import "./PartnersSection.scss";
import {useTranslation} from "react-i18next";
import {partners} from "./partners";

const Partner = ({ name, link, logo }) => (
    <div className="partner-logo col-6 col-md-4 col-lg-3 col-xxl-2-4">
      <a href={link} target="_blank">
        <img width="233" height="100" src={logo} alt={name} loading="lazy" />
      </a>
    </div>
);

const PartnersSection = ({ reference }) => {
  const {t} = useTranslation();
  return (
    <Section reference={reference} id="Partners">
      <Container>
        <Row>
            <Col lg="12" className="section-title">
                <h2>{t('partners.title')}</h2>
                <p>Onze partners zijn degelijke en betrouwbare maatschappijen erkend bij het FSMA. Als onafhankelijk
                    verzekeringsmakelaar bieden wij de beste voorwaarden aan tegen de beste prijs, in alle
                    onafhankelijkheid.</p>
            </Col>
        </Row>
          <Row>
              <Col>
                  <div className="logos__grid">
                      <div className="row justify-content-center">
                          {partners.map(partner => <Partner name={partner.name} logo={partner.logo} link={partner.link} />)}
                      </div>
                  </div>
              </Col>
          </Row>
      </Container>
    </Section>
  );
}

export default PartnersSection;
