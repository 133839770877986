import axaLogo from'../../images/partners/axa.png';
import aedesLogo from'../../images/partners/aedes.png';
import agLogo from'../../images/partners/ag.png';
import allianzLogo from'../../images/partners/allianz.png';
import ammaLogo from'../../images/partners/amma.png';
import baloiseLogo from'../../images/partners/baloise.png';
import pnpLogo from'../../images/partners/pnp.png';

export const partners = [
    {
        name: 'Aedes',
        link: 'https://aedesgroup.be/',
        logo: aedesLogo,
    },
    {
        name: 'AG',
        link: 'https://www.aginsurance.be/',
        logo: agLogo,
    },
    {
        name: 'Allianz',
        link: 'https://allianz.be/',
        logo: allianzLogo,
    },
    {
        name: 'Amma',
        link: 'https://www.amma.be/',
        logo: ammaLogo,
    },
    {
        name: 'AXA',
        link: 'https://www.axa.be/',
        logo: axaLogo,
    },
    {
        name: 'Baloise',
        link: 'https://www.baloise.be/',
        logo: baloiseLogo,
    },
    {
        name: 'Pnp',
        link: 'https://www.pnp.be/',
        logo: pnpLogo,
    }
]