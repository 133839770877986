import React from 'react';

import "./AboutSection.scss";

const Feature = ({ title, icon, text }) => {
  return <div className="feature-item">
    <div className="feature-title">
      <div className="feature-icon">
        {icon}
      </div>
      <h4>{title}</h4>
    </div>
    <div className="feature-description">
      {text}
    </div>
  </div>;
}

export default Feature;
