import React from 'react';
import Section from "../../components/Section";
import Container from "../../components/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/cjs/Col";
import { AspectRatio, CurrencyEuro, Eyeglasses, ChatHeart, Speedometer } from 'react-bootstrap-icons';


import "./AboutSection.scss";
import {useTranslation} from "react-i18next";
import Feature from "./Feature";

const AboutSection = ({ reference }) => {
  const {t} = useTranslation();
  return (
    <Section id="AboutSection" reference={reference}>
      <Container>
        <Row>
          <Col lg="12" className="section-title">
            <h2>{t('about.title')}</h2>
            <p>
              {t('about.text1')}
              {' '}
              <a href={t('url')} rel="noreferrer" target="_blank">{t('url')}</a>
            </p>
          </Col>
        </Row>
        <Row gutt>
          <Col lg="6">
            <Row className="features">
              <Col lg="12">
                <Feature icon={<AspectRatio/>} title={t('about.feature4.title')} text={t('about.feature4.text')}  />
              </Col>
              <Col lg="12">
                <Feature icon={<Speedometer/>} title={t('about.feature5.title')} text={t('about.feature5.text')}  />
              </Col>
            </Row>
          </Col>
          <Col lg="6">
            <Row className="features">
              <Col lg="12">
                <Feature icon={<CurrencyEuro/>} title={t('about.feature1.title')} text={t('about.feature1.text')}  />
              </Col>
              <Col lg="12">
                <Feature icon={<Eyeglasses/>} title={t('about.feature2.title')} text={t('about.feature2.text')}  />
              </Col>
              <Col lg="12">
                <Feature icon={<ChatHeart/>} title={t('about.feature3.title')} text={t('about.feature3.text')}  />
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </Section>
  );
}

export default AboutSection;
